import {
  Bar,
  BarChart,
  CartesianGrid,
  Customized,
  Label,
  LabelList,
  Legend,
  Line,
  Rectangle,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { IAnswer, IFill, IOption, IQuestion, ITargetGroup } from "./interfaces.interface";
import { Formatter } from "recharts/types/component/DefaultLegendContent";

const CustomTooltip = ({ active, payload, targetGroups }: TooltipProps<any, any> & { targetGroups: any[] }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const data = payload[0].payload;

  return (
    <div className="bg-white p-4 border rounded shadow-lg">
      {targetGroups.map((group) => {
        const count = data[`${group.id}_count`];
        const total = data[`${group.id}_total`];
        const percentage = data[`${group.id}_weighted`];

        return (
          <div key={group.id} className="whitespace-nowrap">
            <span className="font-medium">{group.name}: </span>
            <span>
              {percentage.toFixed(1)}% ({count}/{total})
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default function Chart({
  targetGroups,
  fills,
  question,
}: {
  targetGroups: ITargetGroup[];
  fills: IFill[];
  question: IQuestion;
}) {
  // First calculate totals for each target group
  const targetGroupTotals = targetGroups.reduce((acc, group) => {
    const groupTotal = fills.filter(
      (fill) => fill.targetGroupId === group.id && fill.answers.some((answer) => answer.questionId === question.id)
    ).length;
    acc[group.id] = groupTotal;
    return acc;
  }, {} as Record<string, number>);

  // Then calculate the results with within-group percentages
  const groupedData = question.options.map((option) => {
    const targetGroupCounts = targetGroups.reduce((acc, group) => {
      // Count responses for this option within this target group
      const count = fills.filter(
        (fill) =>
          fill.targetGroupId === group.id &&
          fill.answers.some((answer) => answer.optionId === option.id && answer.questionId === question.id)
      ).length;

      // Calculate percentage within the group instead of applying cross-group weights
      const groupTotal = targetGroupTotals[group.id];
      acc[`${group.id}_count`] = count;
      acc[`${group.id}_total`] = groupTotal;
      acc[`${group.id}_weighted`] = groupTotal > 0 ? (count / groupTotal) * 100 : 0;

      return acc;
    }, {} as Record<string, number>);

    return {
      option: option.value,
      ...targetGroupCounts,
    };
  });

  const tooltipFormatter = (name, value, entry) => {
    const groupId: string = entry.dataKey.split("_")[0]; // Extract group ID from the dataKey
    if (!groupId || !entry.payload) return [name, value];

    const count = entry.payload[`${groupId}_count`];
    const total = entry.payload[`${groupId}_total`];
    const weighted = entry.payload[`${groupId}_weighted`];

    return [`${weighted.toFixed(2)}% (${count}/${total})`, value];
  };

  return (
    <ResponsiveContainer width={"100%"} minHeight={80 * question.options.length}>
      <BarChart data={groupedData} layout="vertical" stackOffset="silhouette">
        <Legend verticalAlign="top" />
        <XAxis type="number" domain={[ -100, 100 ]} tick={false} />
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis type="category" dataKey="option" width={300}></YAxis>
        <Tooltip formatter={tooltipFormatter} />
        {targetGroups.map((targetGroup, index) => {
          return (
            <Bar
              key={targetGroup.id}
              stackId={"a"}
              name={targetGroup.name}
              dataKey={targetGroup.id + "_weighted"}
              fill={index === 0 ? "#3366FF" : "#FFB020"}
              maxBarSize={24}
            ></Bar>
          );
        })}
        <ReferenceArea x1={-10} x2={10} stroke="none" fill="black" fillOpacity={0.1} ifOverflow="extendDomain" />
      </BarChart>
    </ResponsiveContainer>
  );
}
