import { Button, Card, Heading, majorScale, Pane, Text, StatusIndicator, toaster } from "evergreen-ui";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DataService } from "./Auth/services";
import Chart from "./Chart";
import { ISurvey, IFill, ITargetGroup } from "./interfaces.interface";
import OverallChart from "./OverallChart";

const getNumberOfFillsByGroup = (fills, group) => {
  return fills.filter((fill) => fill.targetGroupId === group.id).length;
};

const Results = () => {
  let { id } = useParams<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [survey, setSurvey] = useState<ISurvey>();
  const [fills, setFills] = useState<IFill[]>([]);

  useEffect(() => {
    if (id) {
      DataService.getSurveyWithFills(id).then(([survey, fills]) => {
        if (survey && fills) {
          setSurvey(survey);
          setFills(fills);
        }
        setIsLoading(false);
      });
    }
  }, []);

  const copyFillLink = (targetGroup: ITargetGroup) => {
    navigator.clipboard.writeText(window.location.hostname + "/fill/" + targetGroup.id);
    toaster.success(`Kitöltő link vágólapra másolva a(z) ${targetGroup.name} célcsoport számára`);
  };

  if (isLoading) {
    return <Pane>Betöltés...</Pane>;
  }

  return (
    <Pane display={"flex"} flexDirection={"column"} gap={majorScale(2)}>
      {survey && fills && (
        <Pane display={"flex"} flexDirection={"column"} gap={majorScale(2)}>
          <Card background={"white"} padding={majorScale(2)} display={"flex"} gap={majorScale(2)} justifyContent={"space-between"}>
            <Pane display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
              <Pane>
                <Heading>{survey.title}</Heading>
                <Text>{survey.description}</Text>
              </Pane>
              <Pane display={"grid"}>
                <Text>
                  Kitoltes időszaka: {survey.startDate} - {survey.endDate}
                </Text>
                <Text>Kitöltők szama: {fills.length}</Text>
                {survey.contact && <Text>Kapcsolattartó: {survey.contact}</Text>}
                <Button marginTop={majorScale(2)} onClick={(e) => window.print()}>
                  Oldal nyomtatása
                </Button>
              </Pane>
            </Pane>
            {survey.targetGroups.length > 1 && (
              <Pane padding={majorScale(4)}>
                <OverallChart survey={survey} fills={fills} />
              </Pane>
            )}
          </Card>
          <Card background={"white"} padding={majorScale(2)} display={"grid"} gap={majorScale(2)}>
            <Heading>Célcsoport(ok)</Heading>
            {survey.targetGroups?.map((targetGroup, index) => {
              return (
                <Pane key={targetGroup.id} display={"flex"} justifyContent={"space-between"} gap={majorScale(2)} alignItems={"center"}>
                  <Pane display={"flex"} alignItems={"center"}>
                    <StatusIndicator dotSize={16} color={index === 0 ? "#3366FF" : "#FFB020"}></StatusIndicator>
                    {targetGroup.name}
                  </Pane>
                  <Pane display={"flex"} gap={majorScale(2)} alignItems={"center"}>
                    <Text>{getNumberOfFillsByGroup(fills, targetGroup)} kitöltő</Text>
                    <Button onClick={() => copyFillLink(targetGroup)}>Kitöltő link másolása</Button>
                  </Pane>
                </Pane>
              );
            })}
          </Card>
          <Pane display={"flex"} flexDirection="column" gap={majorScale(2)}>
            {survey.questions?.map((question, index) => {
              return (
                <Card key={question.id} background={"white"} padding={majorScale(2)} display={"grid"} gap={majorScale(2)}>
                  <Pane display={"flex"} justifyContent={"space-between"}>
                    <Heading>
                      {index + 1}. {question.question.value}
                    </Heading>
                  </Pane>
                  <Chart question={question} fills={fills} targetGroups={survey.targetGroups}  />
                </Card>
              );
            })}
          </Pane>
        </Pane>
      )}
    </Pane>
  );
};

export default Results;
