import { ISurvey, IFill } from "./interfaces.interface";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis
} from "recharts";

interface OverallChartProps {
  survey: ISurvey;
  fills: IFill[];
}

const LabelFormatter = (survey: ISurvey, optionId: string) => {
  const question = survey.questions.find((q) => q.options.some((o) => o.id === optionId));

  // If a question is found, find the specific option
  if (question) {
    const option = question.options.find((o) => o.id === optionId);
    return option ? `${question.question.value}: ${option.value}` : "Unknown Option";
  }

  return "Unknown Option";
};

const tooltipFormatter = (name, value, entry) => {
  const groupId: string = entry.dataKey.split("_")[0]; // Extract group ID from the dataKey
  if (!groupId || !entry.payload) return [name, value];

  const count = entry.payload[`${groupId}_count`];
  const total = entry.payload[`${groupId}_total`];
  const weighted = entry.payload[`${groupId}_weighted`];

  return [`${weighted.toFixed(2)}% (${count}/${total})`, value];
};

const OverallChart = (props: OverallChartProps) => {
  const targetGroupTotals = props.survey.targetGroups.reduce((acc, group) => {
    const groupTotal = props.fills.filter((fill) => fill.targetGroupId === group.id).length;
    acc[group.id] = groupTotal;
    return acc;
  }, {} as Record<string, number>);

  // Process data for all questions from survey.questions
  const surveyData = props.survey.questions.flatMap((question) => {
    return question.options.map((option) => {
      const targetGroupCounts = props.survey.targetGroups.reduce((acc, group) => {
        const count = props.fills.filter(
          (fill) =>
            fill.targetGroupId === group.id &&
            fill.answers.some((answer) => answer.optionId === option.id && answer.questionId === question.id)
        ).length;

        const groupTotal = targetGroupTotals[group.id];
        acc[`${group.id}_count`] = count;
        acc[`${group.id}_total`] = groupTotal;
        acc[`${group.id}_weighted`] = groupTotal > 0 ? (count / groupTotal) * 100 : 0;

        return acc;
      }, {} as Record<string, number>);

      return {
        question: question.question.value,
        optionId: option.id,
        ...targetGroupCounts,
      };
    });
  });

  const totalOptions = props.survey.questions.reduce((acc, question) => acc + question.options.length, 0);
  const matchingOptions = surveyData.filter((option) => {
    const groupIds = Object.keys(option).filter((key) => key.endsWith("_weighted"));
    const values = groupIds.map((groupId) => option[groupId]);
    const min = Math.min(...values);
    const max = Math.max(...values);
    const res = min > 0 && max > 0 && max - min <= 20;
    return res;
  }).length;

  return (
    <ResponsiveContainer width="100%" minHeight={300} aspect={2}>
      <BarChart width={500} height={300} data={surveyData} stackOffset="silhouette" margin={{ top: 20, right: 30, left: 200, bottom: 0 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="optionId" hide={true} domain={[ -100, 100 ]} tick={false} ></XAxis>
        <Tooltip labelFormatter={(optionId) => LabelFormatter(props.survey, optionId)} formatter={tooltipFormatter} />
        <Legend />
        {props.survey.targetGroups.map((targetGroup, index) => {
          return (
            <Bar
              key={targetGroup.id}
              stackId={"a"}
              name={targetGroup.name}
              dataKey={targetGroup.id + "_weighted"}
              fill={index === 0 ? "#3366FF" : "#FFB020"}
              maxBarSize={24}
            ></Bar>
          );
        })}
        <ReferenceArea y1={-10} y2={10} stroke="none" fill="black" fillOpacity={0.1}>
          <Label value={`${matchingOptions} / ${totalOptions} (${((matchingOptions / totalOptions) * 100).toFixed(2)}%) egyezés`} position="left" />
        </ReferenceArea>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default OverallChart;
